import { toFormatedDate, getSorter } from "system/libraries/utilities";
import SubjectStatus from "system/types/interfaces/SubjectStatus";
import ComplianceStatus from "components/ui/ComplianceStatus";
import TableColumnHeader from "./TableColumnHeader";
import useSearchField from "system/hooks/use-search-field";
import AppLink from "./AppLink";
import usePageNavigation from "system/hooks/use-page-navigation";
import { useEffect } from "react";
import { useUrlSearch } from "system/hooks";

interface IProps {
  subjectStatuses: SubjectStatus[];
  time?: string;
  machineId?: string;
  defaultSort?: { sort: string; sortDesc: boolean };
}

const complianceStatusPriority: { [key: number]: number } = {
  20: 0, // Not yet implemented, can be affected by SLAs, and items marked critical by policy
  1: 1, // Default value, not to be confused with failed to complete a task
  5: 2, // The endpoint has recommendations, or the package/rule is recommended
  8: 3, // All recommended tasks will be complete after a reboot
  9: 4, // All recommended tasks have completed successfully
  7: 5, // (Not applicable and hasn't ran), or Package.Assessment = Applicability.Optional
};

const complianceStatusSorter = (left: SubjectStatus, right: SubjectStatus) => {
  const leftPriority = complianceStatusPriority[left.complianceStatus] ?? 10;
  const rightPriority = complianceStatusPriority[right.complianceStatus] ?? 10;
  return leftPriority - rightPriority;
};

const complianceStatusDescSorter = (left: SubjectStatus, right: SubjectStatus) => {
  return complianceStatusSorter(right, left);
};

const EndpointSubjectStatusList = ({ subjectStatuses, time, machineId, defaultSort }: IProps) => {
  const { sortDesc, sortField } = useSearchField();
  const { navigateToEndpointSubjectHistoryPage } = usePageNavigation();
  const { setMany: setManySearch } = useUrlSearch();

  let sortedSubjRows = subjectStatuses.sort(complianceStatusSorter);

  useEffect(() => {
    // Apply default sorting if set
    setManySearch({
      sort: defaultSort?.sort ?? null,
      desc: defaultSort?.sortDesc ? "1" : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (sortField && sortField !== "complianceStatus") {
    const sortFunc = getSorter(sortField, sortDesc);
    sortedSubjRows = sortedSubjRows.sort(sortFunc);
  } else {
    // Use the specialized sorter for compliance status
    sortedSubjRows = sortedSubjRows.sort(sortDesc ? complianceStatusDescSorter : complianceStatusSorter);
  }

  return (
    <table className="table table-dialog sticky-table tb-endpoint">
      <thead className="sticky-top">
        <tr>
          <TableColumnHeader title="Subject" property="subject" width="110px" />
          <TableColumnHeader title="Package" property="packageName" width="110px" />
          <TableColumnHeader title="State" property="complianceStatus" />
          <TableColumnHeader title="Justification" width="200px" property="complianceJustification" />
          <TableColumnHeader title="Assessment changed" property="referenceJsTime" />
          <TableColumnHeader title="Use case" property="packageType" />
          <TableColumnHeader title="Rule" property="ruleDescription" />
          <TableColumnHeader title="Last exit code" width="100px" property="packageExitCode" />
          <TableColumnHeader title="Status" property="applicabilityMessage" />
        </tr>
      </thead>
      <tbody>
        {sortedSubjRows.map((row, idx) => (
          <tr key={idx}>
            <td>
              {time ? (
                <AppLink onClick={() => navigateToEndpointSubjectHistoryPage(machineId, time, row.subject)}>{row.subject}</AppLink>
              ) : (
                <>{row.subject}</>
              )}
            </td>
            <td>{row.packageName}</td>
            <td>
              <ComplianceStatus code={row.complianceStatus} isUnknown={false} isSubject={true} />
            </td>
            <td>{row.complianceJustification}</td>
            <td>{toFormatedDate(row.referenceJsTime)}</td>
            <td>{row.packageType}</td>
            <td>{row.ruleDescription}</td>
            <td>{row.packageExitCode}</td>
            <td>{row.applicabilityMessage}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EndpointSubjectStatusList;
