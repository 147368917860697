import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { toLocalISOString, toLocale } from "system/libraries/utilities";
import { SubjectStatusTime } from "system/types/wireTypes";
import useUrlSearch from "system/hooks/use-url-search";
import { useConfiguration } from "system/hooks";
import { CustomerLicenseDisplayOption } from "system/types/enums/CustomerLicenseDisplayOption";
import { useMemo } from "react";

interface IProps {
  data: SubjectStatusTime[] | null;
  selectedGraphBy: string;
}

const TheMainChart = ({ data, selectedGraphBy }: IProps) => {
  const urlSearch = useUrlSearch();
  const { currentTenant } = useConfiguration();

  const shouldDisplayLicenseLimit = useMemo(() => {
    if (!data || !currentTenant) {
      return false;
    }

    const maxYValue = data.reduce((max, obj) => {
      const total = obj.nError + obj.nNoncompliant + obj.nUnknown + obj.nCompliant + obj.nCompliantAfterReboot;
      return Math.max(max, total);
    }, 0);

    if (
      currentTenant.customerShowLicenseFlag === CustomerLicenseDisplayOption.ShowLicenseCount &&
      selectedGraphBy === "endpoint" &&
      currentTenant.licensedMachineCount < currentTenant.actualMachineCount
    ) {
      return currentTenant.licensedMachineCount <= maxYValue;
    } else {
      return false;
    }
  }, [currentTenant, data, selectedGraphBy]);

  if (!data || !currentTenant) {
    return <></>;
  }

  const liscenseLimitChart = {
    y: currentTenant.licensedMachineCount,
    borderColor: "white",
    borderWidth: 4,
    strokeDashArray: 5,
    label: {
      borderColor: "gray",
      style: {
        color: "#ffffff",
        fontSize: "12px",
        background: "ffffff",
      },
      text: "License count",
      // position: 'left',
      // offsetX: 122,
      offsetX: -6,
      offsetY: -6,
    },
  };

  const options: ApexOptions = {
    chart: {
      id: "assetDistribution",
      fontFamily: "var(--font-family)",
      toolbar: {
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          download: `<svg height="20" viewBox="0 0 16 16" width="20" xmlns="http://www.w3.org/2000/svg">
            <path d="m3.5 13h9c.2761424 0 .5.2238576.5.5 0 .2454599-.1768752.4496084-.4101244.4919443l-.0898756.0080557h-9c-.27614237 0-.5-.2238576-.5-.5 0-.2454599.17687516-.4496084.41012437-.4919443l.08987563-.0080557h9zm4.41012437-11.99194433.08987563-.00805567c.24545989 0 .44960837.17687516.49194433.41012437l.00805567.08987563v8.792l2.6819805-2.6810873c.1735664-.17356635.4429908-.1928515.6378589-.05785545l.0692479.05785545c.1735663.17356636.1928515.44299076.0578554.6378589l-.0578554.06924788-3.53553391 3.53553392c-.17356635.1735663-.44299075.1928515-.63785889.0578554l-.06924789-.0578554-3.53553391-3.53553392c-.19526214-.19526214-.19526214-.51184463 0-.70710678.17356636-.17356635.44299076-.1928515.6378589-.05785545l.06924788.05785545 2.68198052 2.6810873v-8.792c0-.24545989.17687516-.44960837.41012437-.49194433l.08987563-.00805567z" fill="#BFBDBB"/>
          </svg>`,
        },
      },
      height: 200,
      type: "area",
      stacked: true,
      events: {
        click: (_: any, __?: any, options?: any): void => {
          if (!options) {
            return;
          }

          urlSearch.set("selectedDate", toLocalISOString(new Date(data[options.dataPointIndex].referenceJsTime)));
        },
      },
    },
    fill: {
      opacity: 1,
      type: "solid",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0.9,
      curve: "straight",
    },
    yaxis: {
      labels: {
        formatter: function (value: any) {
          return toLocale(value);
        },
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          day: "MMM dd",
          month: "MMM dd",
          year: "MMM dd 'yy"
        },
      },
      tooltip: {
        enabled: false,
      }
    },
    theme: {
      mode: "dark",
    },
    annotations: {
      yaxis: shouldDisplayLicenseLimit ? [liscenseLimitChart] : [],
    },
    tooltip: {
      enabled: true,
      shared: true,
      inverseOrder: true,
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const total = series.reduce((acc: number, val: any) => acc + val[dataPointIndex], 0);
        const xValue = new Date(w.config.series[0].data[dataPointIndex].x);
        let year = new Intl.DateTimeFormat('en', { year: '2-digit' }).format(xValue);
        let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(xValue);
        let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(xValue);
       
        let tooltipContent = `
          <div style="padding: 10px;">
            <div style="display: block; border-bottom: 1px solid grey;margin-bottom: 8px;"><strong>${month} ${day} '${year}</strong></div>
        `;

        let seriesContent = '';
       
        series.forEach((s: any, index: number) => {
          seriesContent = `
            <div style="display: block;">
              <span style="width: 12px; height: 12px; border-radius: 50%; display: inline-block; background-color: ${w.config.series[index].color}; margin-right: 8px;"></span>
              ${w.config.series[index].name}: ${s[dataPointIndex]}
            </div>
          ` + seriesContent;
        });
        tooltipContent += seriesContent + `<div style="display: block; padding-top: 8px;"><strong>Total: ${total}</strong></div></div>`;
        return tooltipContent;
      },
      x: {
          show: true,
          format: 'MMM dd',
      },
      marker: {
          show: true,
      },
    }
  };

  const series: ApexOptions["series"] = [
    {
      name: "Not reporting",
      data: data.map((subjectStatusTime) => ({ x: new Date(subjectStatusTime.referenceJsTime).getTime(), y: subjectStatusTime.nUnknown })),
      color: "#bfbdbb",
    },
    {
      name: "Critical",
      data: data.map((subjectStatusTime) => ({ x: new Date(subjectStatusTime.referenceJsTime).getTime(), y: subjectStatusTime.nError })),
      color: "#f3796c",
    },
    {
      name: "Has recommendations",
      data: data.map((subjectStatusTime) => ({
        x: new Date(subjectStatusTime.referenceJsTime).getTime(),
        y: subjectStatusTime.nNoncompliant,
      })),
      color: "#ffa031",
    },
    {
      name: "Compliant pending reboot",
      data: data.map((subjectStatusTime) => ({
        x: new Date(subjectStatusTime.referenceJsTime).getTime(),
        y: subjectStatusTime.nCompliantAfterReboot,
      })),
      color: "#42abdd",
    },
    {
      name: "Compliant",
      data: data.map((subjectStatusTime) => ({
        x: new Date(subjectStatusTime.referenceJsTime).getTime(),
        y: subjectStatusTime.nCompliant,
      })),
      color: "#89d35d",
    },
  ];

  return <ReactApexChart height={250} options={options} series={series} type="area" />;
};

export default TheMainChart;
